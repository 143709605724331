export const toc = (html, toc) => {
  const hook = new RegExp('\\[\\[toc\\]\\]', 'g');
  const olToc = toc.replace(/ul>/gm, 'ol>');
  const replaceText = `<section class="toc">${olToc}</section>`;

  html = html.replace(hook, replaceText);

  // html = replacer(html, 'info')

  return html;
}

// インスタグラム用のマークダウン変換コード
// 技術用のブログには不要と思われるため、いったん停止
// const replacer = (html, hook) => {
//   const regex = new RegExp('^.*\n(?={\.' + hook + ').*$', 'gm')
//   const matched = html.match(regex)
//   console.log(`matched=${matched}`)
//
//   const replacing = replacingCode(matched)
//
//   return html.replace(regex, replacing);
// }
//
// const replacingCode = (matchedText, hook) => {
//   // Stringにして、文字列操作ができるように定義
//   let block = String(matchedText).replace(/\r?\n/g, '');
//
//   const regex = new RegExp('^<p>(\s*.*){\.'+ hook + '}<\/p>$', 'm');
//   const replacing = block.replace(regex, '<div class="info">$1</div>')
//
//   console.log(`replacing=${replacing}`)
//   return replacing;
// }
