import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout-blog-post"
import SEO from "../components/organisms/seo"


class BlogPostPage extends React.Component {
  render() {
    const post = this.props.data.post
    const carousel = this.props.data.carousel
    const siteTitle = this.props.data.site.siteMetadata.title
    // const { previous, next } = this.props.pageContext

    return (
      <>
        <SEO
          title = {`${post.frontmatter.title} | ${siteTitle}`}
          digest = {post.frontmatter.digest}
          category = {post.frontmatter.category}
          tag = {post.frontmatter.tag}
          img = {post.frontmatter.img.publicURL}
          slug = {post.fields.slug}
          birthTime = {post.parent.birthTime}
          modifiedTime = {post.parent.modifiedTim}
          />
        <Layout
          location={this.props.location}
          title={siteTitle}
          post={post}
          carousel={carousel}
          >
        </Layout>
      </>
    )
  }
}

export default BlogPostPage

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!, $tag: [String!]
  ) {
    site {
      siteMetadata {
        title
      }
    }
    post:markdownRemark(
      fields: { slug: { eq: $slug } }
    ) {
      html
      tableOfContents(heading: "", maxDepth: 2)
      fields {
        slug
      }
      frontmatter {
        title
        digest
        category
        tag
        img {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        giphy
      }
      parent {
        ... on File {
          birthTime(formatString: "YYYY-MM-DD")
          modifiedTime(formatString: "YYYY-MM-DD")
        }
      }
    },
    carousel:allMarkdownRemark(
      filter: { frontmatter: { tag: { in: $tag } } },
      sort: { fields: [fields___slug], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            tag
            digest
            title
            img {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
