import React from "react"
// import { rhythm } from "../utils/typography"
import Footer from "./atoms/footer"
import Sitename from "./moleculs/siteTitle"
import Post from "./organisms/post"
import Carousel from "./organisms/carousel"
import Bio from "./organisms/bio"
import Nav from "./organisms/navigation"
import {ProvideMediaMatchers, MediaMatcher} from "react-media-match"

class LayoutBlogPost extends React.Component {
  render() {
    const { location, title, post, carousel } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <>
        <header>
          <Sitename location={location} rootPath={rootPath} />
          <Nav className={`navi text-4xl`} />
        </header>

        <main>
          <div className="mx-auto w-5/6 lg:w-1/2">
            <Post entry={post} />
          </div>
          <Carousel sortedTags={carousel} />
          <Bio />
        </main>

        <Footer />

        {/*<ProvideMediaMatchers>
          <MediaMatcher
            mobile={<div>mobile</div>}
            tablet={<p>tablet</p>}
            desktop={"render desktop"}
            />
        </ProvideMediaMatchers>*/}
      </>
    )
  }
}

export default LayoutBlogPost
