import React from "react"
import Giphy from "../atoms/giphy"
import TagBtns from "../moleculs/tagButtons"
import Heading from "../atoms/heading"
import Breadcrumbs from "../moleculs/breadcrumbs"
import { rhythm, scale } from "../../utils/typography"
import Style from "./post.module.css"

import * as lib from "../../utils/api"


const Post = (props) => {
  const post = props.entry
  const postInfo = props.entry.frontmatter
  const postParent = props.entry.parent

  return(
    <article>
      <header className="mb-48">
        <div className="flex items-center justify-between">
          <Breadcrumbs category={ postInfo.category } />
          <div className="hidden md:block">
            <TagBtns tags={ postInfo.tag } />
          </div>
        </div>
        <Heading tagName="h1" size="mt-40 mb-20 text-5xl text-color-silver-pink"> { postInfo.title } </Heading>
        <div className="text-sm font-bold" style={ {...scale(-1 / 5)} }>
          <p>{ postParent.birthTime }</p>
          <p className="text-gray-600">(LastUpdated: {postParent.modifiedTime})</p>
        </div>
      </header>

      <section
        className={Style.entry}
        dangerouslySetInnerHTML={
          { __html: lib.toc(post.html, post.tableOfContents) }
        }
        style={ {...scale(-1 / 5)} }
      />
      <hr
        className="border-bottom border-black w-full"
        style={{
          height:`1px`,
          marginTop: rhythm(3),
          marginBottom: rhythm(3),
        }}
      />

    <div className="mb-2 text-center">
      <Heading tagName="h4" size="text-md font-bold mb-2">
        タグに関連づけられた記事
      </Heading>
      <TagBtns tags={ postInfo.tag } />
    </div>

    </article>
  )
}

export default Post
