import React from "react"
import Img from '../atoms/image'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

const Carousel = (props) => {
  const items = props.sortedTags
  const totalCount = props.sortedTags.totalCount

  return(
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={100}
      totalSlides={totalCount}
      visibleSlides="1"
      className="mx-auto w-2/3 sm:w-1/3 relative"
      >
      <Slider>
        {
          items.edges.map(post=>
            <Slide key={ post.node.id } className='relative flex items-center'>
              { post.node.frontmatter.img &&
                <Img frontmatter={ post.node.frontmatter } />
              }
              <p
                className='absolute top-0 w-full h-full p-3 sm:text-lg'
                style={{
                  backgroundColor:"rgba(0, 0, 0, 0.67)",
                  minHeight:"30%"
                }}
              >
                <span className='text-white align-middle'>{post.node.frontmatter.title}</span>
              </p>
            </Slide>
          )
        }
      </Slider>
      <ButtonBack className="absolute" style={{top:`50%`, left:`-34px`, marginTop:`-15px`}}>
        <svg width="30px" height="30px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-circle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgb(103, 103, 103)" d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z"></path></svg>
      </ButtonBack>
      <ButtonNext className="absolute" style={{top:`50%`, right:`-34px`, marginTop:`-15px`}}>
        <svg width="30px" height="30px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-circle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"></path></svg>
      </ButtonNext>
    </CarouselProvider>
  )
}
export default Carousel
